import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal, Button, Select, Input, Radio, Form } from 'antd';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import { Post } from '../../utils/request';

import styles from './WhyDisconnect.less';

import featureAliIcon from '../../assets/setting/dsers_feature_ali.png';
import featureEditIcon from '../../assets/setting/dsers_feature_edit.png';
import featureOrderIcon from '../../assets/setting/dsers_feature_order.png';
import featureRewardIcon from '../../assets/setting/dsers_feature_reward.png';

import freeImgEn from '../../assets/setting/disconnect_modal_en.png';
import freeImgFr from '../../assets/setting/disconnect_modal_fr.png';
import { emailRegExp } from 'utils/constants';

const freeImg = intl.getInitOptions().currentLocale === 'fr-FR' ? freeImgFr : freeImgEn;

const { Option } = Select;
const { TextArea } = Input;

const featureReasonList = [
  {
    name: intl.get('whyDisconnect.reason.feature.ali_account'),
    id: 'feature.ali_account'
  },
  {
    name: intl.get('whyDisconnect.reason.feature.order_process'),
    id: 'feature.order_process',
    action: 'free_one_more_month'
  },
  {
    name: intl.get('whyDisconnect.reason.feature.product_edit'),
    id: 'feature.product_edit',
    action: 'free_one_more_month'
  },
  {
    name: intl.get('whyDisconnect.reason.feature.import'),
    id: 'feature.import',
    action: 'free_one_more_month'
  },
  {
    name: intl.get('whyDisconnect.reason.feature.mapping'),
    id: 'feature.mapping',
    action: 'free_one_more_month'
  }
];
const priceReasonList = [
  {
    name: intl.get('whyDisconnect.reason.price.expensive'),
    id: 'price.expensive'
    // action: 'free_one_more_month'
  }
];
const otherReasonList = [
  {
    name: intl.get('whyDisconnect.reason.other.reinstall'),
    id: 'other.reinstall'
  },
  {
    name: intl.get('whyDisconnect.reason.other.fullillment_center'),
    id: 'other.fullillment_center',
    action: 'free_one_more_month'
  },
  {
    name: intl.get('whyDisconnect.reason.other.close_store'),
    id: 'other.close_store'
  },
  {
    name: intl.get('whyDisconnect.reason.other.test_store'),
    id: 'other.test_store'
  }
];

const dsersFeatures = [
  {
    description: 'whyDisconnect.dsers_feature.ali',
    icon: featureAliIcon,
    className: styles.feature1
  },
  {
    description: 'whyDisconnect.dsers_feature.import',
    icon: featureEditIcon,
    className: styles.feature2
  },
  {
    description: 'whyDisconnect.dsers_feature.order',
    icon: featureOrderIcon,
    className: styles.feature3
  },
  {
    description: 'whyDisconnect.dsers_feature.reward',
    icon: featureRewardIcon,
    className: styles.feature4
  }
];

function DisconnectStageOne(props) {
  const { handleContact, handleDisconnect, unDisconnect } = props;
  return (
    <div className={styles.stageOne}>
      <div className={styles.modalTopImg} />
      <div className={styles.dec}>
        <h3 className={styles.title}>{intl.get('whyDisconnect.need_help')}</h3>
        <p className={styles.tip}>{intl.get('whyDisconnect.need_help_dec')}</p>
      </div>
      <p className={styles.contact}>
        <Button
          href="mailto:support@dserspro.com"
          type="primary"
          size="large"
          onClick={handleContact}
        >
          {intl.get('whyDisconnect.contact')}
        </Button>
      </p>
      <div className={styles.dec}>
        <h3 className={styles.title}>
          {intl.get('whyDisconnect.lose_feature')}
        </h3>
        <div className={styles.dsersFeatures}>
          {dsersFeatures.map((item, index) => {
            return (
              <div
                className={`${item.className} ${styles.featureItem}`}
                key={index}
              >
                <span>{intl.getHTML(item.description)}</span>
                <img src={item.icon} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.dec}>
        <h3 className={styles.title}>
          {intl.get('whyDisconnect.need_to_uninstall')}
        </h3>
        <p className={styles.tip}>
          {intl.get('whyDisconnect.need_to_uninstall_dec')}
        </p>
      </div>
      <div className={styles.buttonLine}>
        <Button size="large" onClick={handleDisconnect} data="bk">
          {intl.get('whyDisconnect.disconnect')}
        </Button>
        <Button size="large" type="primary" onClick={unDisconnect} data="dx">
          {intl.get('whyDisconnect.stay')}
        </Button>
      </div>
    </div>
  );
}

const moduleButtons = [
  {
    title: intl.get('whyDisconnect.reason.feature.btn'),
    value: 'feature'
  },
  {
    title: intl.get('whyDisconnect.reason.price.btn'),
    value: 'price'
  },
  {
    title: intl.get('whyDisconnect.reason.other.btn'),
    value: 'other'
  }
];

const textAreaAutoSize = { minRows: 2, maxRows: 3 };

function DisconnectStageTwo({
  showFreeAction,
  goNextStage,
  handleDisconnect,
  unDisconnect
}) {
  const [activeModule, setActiveModule] = useState('feature');
  const activeModuleActions = useMemo(() => {
    switch (activeModule) {
      case 'feature':
        return featureReasonList;
      case 'price':
        return priceReasonList;
      case 'other':
        return otherReasonList;
      default:
        return [];
    }
  }, [activeModule]);

  const [selectedReason, setSelectedReason] = useState(undefined);
  const selectedReasonItem = useMemo(() => {
    return activeModuleActions.find(item => item.id === selectedReason) || {};
  }, [selectedReason, activeModuleActions]);

  const showFreeTip = selectedReasonItem.action && showFreeAction;

  const [suggestion, setSuggestion] = useState('');

  const [otherPlatform, setOtherPlatform] = useState('');

  const handleSuggestionChange = useCallback(e => {
    setSuggestion(e.target.value);
  }, []);

  const handleOtherPlatformChange = useCallback(e => {
    setOtherPlatform(e.target.value);
  }, []);

  const handleClickDisconnect = () => {
    handleDisconnect({
      reason: selectedReasonItem.name,
      suggestion,
      otherPlatform
    });
  };

  const clearValues = useCallback(() => {
    setOtherPlatform('');
    setSuggestion('');
    setSelectedReason(undefined);
  }, []);

  useEffect(() => {
    clearValues();
  }, [activeModule]);

  return (
    <div className={styles.stageTwo}>
      <div
        className={`${styles.modalTopImg} ${showFreeTip ? styles.hidden : ''}`}
      />
      {showFreeTip ? (
        <img src={freeImg} className={styles.modalFreeImg} />
      ) : null}
      <div className={styles.inputLabel}>
        {intl.get('whyDisconnect.which_module')}
      </div>
      <div className={styles.moduleSelect}>
        {moduleButtons.map(item => {
          const active = item.value === activeModule;
          return (
            <Button
              data="dx"
              onClick={() => setActiveModule(item.value)}
              className={active ? styles.active : ''}
              type={active ? 'primary' : 'default'}
              key={item.value}
            >
              {item.title}
            </Button>
          );
        })}
      </div>

      {activeModule ? (
        <div>
          <div>
            <Select
              placeholder={intl.get('whyDisconnect.select_reason')}
              value={selectedReason}
              className={styles.reasonSelect}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={setSelectedReason}
            >
              {activeModuleActions.map(item => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            <div className={styles.inputLabel}>
              {intl.get('whyDisconnect.suggestion_title')}
            </div>
            <Input.TextArea
              maxLength={500}
              onChange={handleSuggestionChange}
              value={suggestion}
              autoSize={textAreaAutoSize}
            />
            <div className={styles.textAreaCount}>{suggestion.length}/500</div>
          </div>
          <div style={{marginTop: -20}}>
            <div className={styles.inputLabel}>
              {intl.get('whyDisconnect.other_platform_title')}
            </div>
            <Input.TextArea
              maxLength={500}
              onChange={handleOtherPlatformChange}
              value={otherPlatform}
              autoSize={textAreaAutoSize}
            />
            <div className={styles.textAreaCount}>
              {otherPlatform.length}/500
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.buttonLine}>
        <Button
          size="large"
          disabled={!selectedReasonItem.id}
          onClick={handleClickDisconnect}
          data="bk"
        >
          {intl.get('whyDisconnect.disconnect')}
        </Button>
        {showFreeTip ? (
          <Button size="large" type="primary" onClick={goNextStage} data="dx">
            {intl.get('whyDisconnect.btn_get_free')}
          </Button>
        ) : (
          <Button size="large" type="primary" onClick={unDisconnect} data="dx">
            {intl.get('whyDisconnect.stay')}
          </Button>
        )}
      </div>
    </div>
  );
}

function StageStayWithFreeBase({ handleStayWithFree, form }) {
  const { getFieldDecorator, getFieldError, getFieldValue } = form;

  const email = getFieldValue('email');

  const buttonDisabled = !email || getFieldError('email');

  const handleGetFree = () => {
    if (getFieldError('email')) {
      return false;
    }
    // const email = getFieldValue('email');
    // console.log(email);
    handleStayWithFree({ email });
  };
  return (
    <div className={styles.stageStayWithFree}>
      <div className={styles.stayWithFree} />
      <h3>{intl.getHTML('whyDisconnect.stay_for_free')}</h3>
      {/* <h3>{intl.getHTML('whyDisconnect.stay_for_free')}</h3> */}
      <div>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'required' },
              {
                pattern: emailRegExp,
                message: intl.get('bargain.input_email')
              },
              {
                max: 500
              }
            ]
          })(<Input placeholder="Email" style={{ width: 400 }} type="email" />)}
        </Form.Item>
      </div>
      <Button
        disabled={buttonDisabled}
        size="large"
        type="primary"
        onClick={handleGetFree}
        data="dx"
      >
        {intl.get('whyDisconnect.stay')}
      </Button>
    </div>
  );
}

const StageStayWithFree = Form.create()(React.memo(StageStayWithFreeBase));

function StageDisconnected() {
  const handleUpdate = () => {
    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'update'
    });
    window.location.reload();
  };
  return (
    <div className={styles.stageDisconnected}>
      <div className={styles.disconnectedImg} />
      <h3 className={styles.title} style={{ textAlign: 'center' }}>
        {intl.getHTML('whyDisconnect.callback_title')}
      </h3>
      <Button onClick={handleUpdate} type="primary">
        {intl.get('whyDisconnect.update')}
      </Button>
      <p>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.blog.dserspro.com/post/how-to-add-a-woocommerce-store-to-dsers-pro"
        >
          {intl.get('whyDisconnect.woo_free')}
        </a>
      </p>
    </div>
  );
}

const closableArr = [0];

const stageWidth = [600, 600, 447, 421];

function WhyDisconnect(props) {
  const { plan, userInfo, unDisconnect, shopifyStoreId, dispatch } = props;
  console.log(plan, userInfo);

  const [stage, setStage] = useState(0);

  const goNextStage = () => {
    setStage(stage + 1);
  };

  useEffect(() => {
    if (!shopifyStoreId) {
      setStage(0);
    }
  }, [shopifyStoreId]);

  const closable = closableArr.includes(stage);

  const width = stageWidth[stage];

  console.log(closable)

  const showFreeAction = useMemo(() => {
    const currentStore = userInfo.stores.find(
      item => item.id === shopifyStoreId
    );
    return (
      plan &&
      plan.type &&
      plan.type > 1 &&
      userInfo &&
      !userInfo.apply_free &&
      currentStore &&
      currentStore.status === 1
    );
  }, [plan, userInfo, shopifyStoreId]);

  function getStoreDomain() {
    // console.log(userInfo.stores, shopifyStoreId);
    const currentStore = userInfo.stores.find(
      item => item.id === shopifyStoreId
    );

    return `https://${currentStore?.shop_name}.myshopify.com/admin/apps`;
  }
  async function handleStayWithFree({ email }) {
    // setStage(2);
    if (!shopifyStoreId) {
      return;
    }
    const res = await Post('/users/uninstall/free', {
      data: {
        store_id: shopifyStoreId,
        email
      }
    });

    if (res && [2000, 2010].includes(res.code)) {
      dispatch({ type: 'login/updateUserInfo' });
      unDisconnect();
    }
  }
  async function handleDisconnect(params = {}) {
    // console.log(params);
    const { reason, suggestion, otherPlatform } = params;
    if (!reason) {
      return;
    }

    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'disconnect',
      value: reason
    });

    const res = await Post('/users/uninstall', {
      data: {
        store_id: props.shopifyStoreId,
        reason,
        suggestion,
        move_to: otherPlatform
      }
    });

    if (res && [2000, 2010].includes(res.code)) {
      window.open(getStoreDomain(), '_blank');
      // props.disconnectCallback();
      setStage(3);
    }
  }

  function handleContact() {
    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'contact'
    });
  }

  return (
    <Modal
      visible={!!shopifyStoreId}
      footer={null}
      closable={false}
      maskClosable={closable}
      width={width}
      onCancel={unDisconnect}
      className={styles.disconnectStoreModal}
      destroyOnClose
    >
      {stage === 0 ? (
        <DisconnectStageOne
          handleContact={handleContact}
          handleDisconnect={goNextStage}
          unDisconnect={unDisconnect}
        />
      ) : null}
      {stage === 1 ? (
        <DisconnectStageTwo
          handleContact={handleContact}
          handleDisconnect={handleDisconnect}
          unDisconnect={unDisconnect}
          showFreeAction={showFreeAction}
          goNextStage={goNextStage}
        />
      ) : null}
      {stage === 2 ? (
        <StageStayWithFree handleStayWithFree={handleStayWithFree} />
      ) : null}
      {stage === 3 ? <StageDisconnected /> : null}
    </Modal>
  );
}

export default connect(({ user, login }) => {
  return {
    plan: user.plan,
    userInfo: login.userInfo
  };
})(React.memo(WhyDisconnect));
