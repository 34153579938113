import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { Get } from 'utils/request';
import { connect } from 'dva';
import { CommonDataCollect } from 'utils/utils';
import intl from 'react-intl-universal';
import { LinkStoreAction } from 'actions'
import { CAM002 } from 'components/Cam';


function index({ plan }) {
    const [limitNumber, setLimitNumber] = useState({
        shopifyLimit: {},
        aliLimit: {}
    })

    const [isModalVisiable, setIsModalVisiable] = useState(false);

    useEffect(() => {
        getProductLimit()
    }, [])

    const closeDetail = () => {
        setIsModalVisiable(false)
    }

    const getProductLimit = async () => {
        const request = [Get('/set/limit/shopify', {}), Get('/set/limit/ae', {})]
        await Promise.allSettled(request).then(res => {
            if (res[0].value.code === 2000 && res[1].value.code === 2000) {
                setLimitNumber({
                    shopifyLimit: res[0].value.data,
                    aliLimit: res[1].value.data
                })
            }
        })
    }

    const renderStyle = (data) => {
        const percent = Math.round(data?.count / data?.limit * 10000) / 100.00
        if (percent == 0) {
            return {}
        }
        if (percent > 0 && percent < 50) {
            return { backgroundColor: '#8BC34A', width: `${percent}%` }
        }
        if (percent >= 50 && percent < 90) {
            return { backgroundColor: '#64B5F6', width: `${percent}%` }
        }
        if (percent >= 90) {
            return { backgroundColor: '#F44436', width: `${percent > 100 ? 100 : percent}%` }
        }
    }

    const productLimitStatus = () => {
        const shopifyLimit = Math.round(limitNumber?.shopifyLimit?.count / limitNumber?.shopifyLimit?.limit * 10000) / 100.00
        const aeLimit = Math.round(limitNumber?.aliLimit?.count / limitNumber?.aliLimit?.limit * 10000) / 100.00
        if (shopifyLimit >= 90 || aeLimit >= 90) {
            return <span className={styles.redDot}></span>
        } else {
            return null
        }
    }

    const handleToPrice = () => {
        CommonDataCollect({
            event_type: "overlimit_pop",
            action: "Products-limit-top"
        }).then(res => {
            // window.location.href = '/app/pricing?select=1'
            // window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit')
            setIsModalVisiable(true)
        })
    }

    return (
        <div className={styles.productLimitBox}>
            <div className={styles.topTitle}>Product limit {productLimitStatus()}</div>
            <div className={styles.line}></div>
            <div className={styles.bottom}>
                <div className={styles.bottomBox}>
                    <div>{intl.get('product.productLimitTitle')}</div>
                    <div>{intl.get('product.productLimitSub')}</div>
                    <div>
                        <div style={renderStyle(limitNumber.shopifyLimit)}></div>
                    </div>
                    <div><span>{limitNumber.shopifyLimit?.count}/{limitNumber.shopifyLimit?.limit}</span> {[3, 5].includes(plan.type) ? null : <span className={styles.update} onClick={handleToPrice}>UPGRADE</span>} </div>
                </div>
                <div className={styles.bottomBox}>
                    <div>{intl.get('product.aeLimitTitle')}</div>
                    <div>{intl.get('product.aeLimitSub')}</div>
                    <div>
                        <div style={renderStyle(limitNumber.aliLimit)}></div>
                    </div>
                    <div><span>{limitNumber.aliLimit?.count}/{limitNumber.aliLimit?.limit}</span>{[3, 5].includes(plan.type) ? null : <span className={styles.update} onClick={handleToPrice}>UPGRADE</span>} </div>
                </div>
            </div>
            {
            isModalVisiable ? <CAM002 camType={'ProductsLimit'} closeDetail={closeDetail} /> : null 
            }
        </div>
    )
}

export default connect(({ user }) => ({
    plan: user.plan,
}))(index)