import React from 'react';
import { Modal, Button } from 'antd';

import intl from 'react-intl-universal';

import styles from './DisconnectCallback.less';
import disconnectCallbackImage from '../../assets/img/business-partnership.png';

function DisconnectCallback(props) {
  function handleUpdate() {
    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'update'
    });
    window.location.reload();
  }

  return (
    <Modal
      visible={props.visible}
      footer={null}
      closable={false}
      centered
    >
      <h3 className={styles.title}>
        {intl.getHTML('whyDisconnect.callback_title')}
      </h3>
      <img src={disconnectCallbackImage} className={styles.img} />
      <div className={styles.buttonLine}>
        <Button
          onClick={handleUpdate}
          type="primary"
          data="dx"
        >
          {intl.get('whyDisconnect.update')}
        </Button>
      </div>
    </Modal>
  );
}

export default DisconnectCallback;
